



























import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
  components: { OLecturersList: () => import('@/components/organisms/globalTools/lecturer/o-lecturers-list.vue')},

  setup(){
    const state = reactive({
      search: ''
    })

    return { state }
  }
})
